import { AppProvider, i18Init, namespaces, constants } from "@sundae/gov-toolkit";
import { Link } from "@sundae/ui-toolkit";
import ReactDOM from "react-dom/client";
import { useI18N } from "@sundae/react-hooks";
import resourcesToBackend from "i18next-resources-to-backend";

import "./tailwind.scss";
import { EEX_PATHS } from "./types";
import { IConfigBrand } from "@sundae/gov-toolkit/dist/types/types/AppConfig";

(async () => {
  i18Init(
    (language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`).then(({ default: resources }) =>
        callback(null, resources)
      );
    },
    (options) => {
      options.ns = [...namespaces, "newm"];
      options.debug = false;
      return options;
    }
  );
})();

const App = () => {
  const { i18n, t } = useI18N("newm");
  i18n.use(
    resourcesToBackend((language, namespace, callback) => {
      const path = `./translations/${language}/${namespace}.json`;
      import(path).then(({ default: resources }) => callback(null, resources));
    })
  );

  const brand: Partial<IConfigBrand> = {
    title: t("brand.title"),
    description: t("brand.description"),
    classes: {
      buttons: {
        colored: "!bg-gradient-to-tr !from-primary !to-secondary",
        primary: "!bg-secondary-200",
        tabs: "radix-state-active:!text-secondary-200",
      },
    },
    images: {
      audit_pending: "/static/images/audit_pending.png",
      casting_a_vote: "/static/images/casting_a_vote.png",
      fatal_error: "/static/images/fatal_error.png",
      not_enough_tokens: "/static/images/not_enough_tokens.png",
      proposal_created: "/static/images/proposal_created.png",
    },
  };

  return (
    <AppProvider
      componentProps={{
        header: {
          renderLogo: () => (
            <Link to={constants.paths.EPATHS.PROPOSAL_OVERVIEW}>
              <img className="h-[40px]" src="/static/images/logo.png" />
            </Link>
          ),
          renderTertiaryNav: null,
          showThemeToggle: false,
          navigationItems: [
            {
              to: "/",
              title: t("navigation.home"),
              getActiveProps() {
                return {
                  className: "active-nav-item",
                };
              },
            },
            {
              to: "/create",
              title: t("navigation.create"),
              getActiveProps() {
                return {
                  className: "active-nav-item",
                };
              },
            },
            {
              dropdown: {
                menuTitle: t("navigation.more"),
                menuContent: [
                  {
                    to: EEX_PATHS.HOME,
                    title: t("navigation.newm"),
                    description: t("navigation.newm_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.TWITTER,
                    title: t("navigation.twitter"),
                    description: t("navigation.twitter_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.YOUTUBE,
                    title: t("navigation.youtube"),
                    description: t("navigation.youtube_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.FAQ,
                    title: t("navigation.faq"),
                    description: t("navigation.faq_desc"),
                    external: true,
                  },
                ],
              },
            },
          ],
        },
      }}
      brand={brand}
    />
  );
};

const target = document.querySelector("#app");
const root = target && ReactDOM.createRoot(target);
if (root) {
  root.render(<App />);
}
